import path from "path";

import {authorizedClientRequest} from "@/api/csr-request";
import {
  File as FileModel,
  UploadUrlDocument,
  UploadUrlMutation,
  UploadUrlMutationVariables,
  DownloadUrlMutationVariables,
  FinishUploadDocument,
  FinishUploadMutationVariables,
  DownloadUrlDocument,
  DeleteFileMutationVariables,
  DeleteFileDocument,
  DownloadUrlMutation,
  FilesDocument,
  FilesQueryVariables,
  UpdateFileMutationVariables,
  UpdateFileDocument,
  FileDocument,
  UploadOverwriteUrlMutationVariables,
  UploadOverwriteUrlDocument,
  UploadOverwriteUrlMutation,
} from "@/shared/codegen/types";

import {FileEntityDescriptor} from "./file-entity-descriptor";

type UploadRequest = UploadUrlMutationVariables & {
  file: File;
  entityDescriptor?: FileEntityDescriptor;
};

type FilesRequest = FilesQueryVariables & {
  entityDescriptor?: FileEntityDescriptor;
};

type UploadToUrlRequest = {
  id: string;
  name: string;
  file: File;
  url: string;
  method: string;
  headers?: Record<string, string>;
};

async function fetchFile(variables: {id: string}) {
  const result = await authorizedClientRequest({
    document: FileDocument,
    variables,
  });
  return result.file;
}

async function fetchFiles(request: FilesRequest) {
  const path = request.entityDescriptor ? buildPath(request.entityDescriptor) : undefined;
  const result = await authorizedClientRequest({
    document: FilesDocument,
    variables: {
      ...request,
      path,
    },
  });
  return result.files;
}
function removeFileExtension(file: File) {
  const fileName = file.name;
  const lastDotIndex = fileName.lastIndexOf(".");

  if (lastDotIndex === -1) return fileName; // No extension found
  return fileName.substring(lastDotIndex, fileName.length);
}

async function upload(request: UploadRequest): Promise<FileModel> {
  const extension = (request.extension || removeFileExtension(request.file) || "").replace(".", "").toLowerCase();
  const name = request.name || path.basename(request.file.name).toLowerCase();
  const contentType = request.contentType || request.file.type;
  const response = await uploadUrl({
    deviceId: request.deviceId,
    name,
    path: request.entityDescriptor ? buildPath(request.entityDescriptor) : undefined,
    isPrivate: request.isPrivate,
    extension,
    contentType,
    notes: request.notes,
    tags: request.tags,
  });
  const uploadRequest = {
    id: response.id,
    name: `${name}.${extension}`,
    file: request.file,
    url: response.url,
    method: response.method,
    headers: response.headers,
  };

  await uploadToURL(uploadRequest);
  return finishUpload({id: response.id});
}

async function overwrite(request: UploadOverwriteUrlMutationVariables & {file: File}) {
  const extension = (path.extname(request.file.name) || "").replace(".", "").toLowerCase();
  const name = path.basename(request.file.name).toLowerCase();
  const response = await uploadOverwriteUrl({
    id: request.id,
  });
  const uploadRequest = {
    id: response.id,
    name: `${name}.${extension}`,
    file: request.file,
    url: response.url,
    method: response.method,
    headers: response.headers,
  };
  await uploadToURL(uploadRequest);
}

async function uploadToURL(request: UploadToUrlRequest) {
  const formData = new FormData();

  formData.append("file", request.file, request.name);

  const headers: Record<string, string> = request.headers ? request.headers : {};
  const fetchResponse = await fetch(request.url, {
    method: request.method,
    headers,
    body: formData.get("file"),
  });

  if (!fetchResponse.ok) throw new Error("Error while uploading file.");
}

async function uploadOverwriteUrl(
  variables: UploadOverwriteUrlMutationVariables
): Promise<UploadOverwriteUrlMutation["uploadOverwriteUrl"]> {
  const response = await authorizedClientRequest({
    document: UploadOverwriteUrlDocument,
    variables,
  });
  if (!response.uploadOverwriteUrl) throw new Error("Couldn't retrieve uploadURL from server.");
  return response.uploadOverwriteUrl;
}

async function uploadUrl(variables: UploadUrlMutationVariables): Promise<UploadUrlMutation["uploadUrl"]> {
  const response = await authorizedClientRequest({
    document: UploadUrlDocument,
    variables,
  });
  if (!response.uploadUrl) throw new Error("Couldn't retrieve uploadURL from server.");
  return response.uploadUrl;
}

async function finishUpload(variables: FinishUploadMutationVariables): Promise<FileModel> {
  const response = await authorizedClientRequest({
    document: FinishUploadDocument,
    variables,
  });

  if (!response.finishUpload) throw new Error("Couldn't mark the upload as finished.");
  return response.finishUpload;
}

function buildPath(entityDescriptor: FileEntityDescriptor) {
  const entityPath = `${entityDescriptor.entityType}/${entityDescriptor.entityId}`;
  if ("parentEntityType" in entityDescriptor) {
    return `${entityDescriptor.parentEntityType}/${entityDescriptor.parentEntityId}/${entityPath}`;
  }
  return entityPath;
}

async function updateFile(variables: UpdateFileMutationVariables): Promise<FileModel> {
  const response = await authorizedClientRequest({
    document: UpdateFileDocument,
    variables,
  });
  if (!response.updateFile) throw new Error("Couldn't update the file.");

  return response.updateFile;
}

async function downloadUrl(variables: DownloadUrlMutationVariables): Promise<DownloadUrlMutation["downloadUrl"]> {
  const response = await authorizedClientRequest({
    document: DownloadUrlDocument,
    variables,
  });
  if (!response.downloadUrl) throw new Error("Couldn't retrieve the download url.");

  const result = response.downloadUrl;
  return {
    id: result.id,
    url: result.url,
    expirationDate: result.expirationDate ? new Date(result.expirationDate) : undefined,
  };
}

async function deleteFile(variables: DeleteFileMutationVariables): Promise<true> {
  const response = await authorizedClientRequest({
    document: DeleteFileDocument,
    variables,
  });
  if (!response.deleteFile) throw new Error("Couldn't delete the file.");
  return true;
}

export const FileModule = {
  fetchFile,
  fetchFiles,
  upload,
  overwrite,
  downloadUrl,
  updateFile,
  deleteFile,
};
