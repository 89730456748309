import {authorizedClientRequest} from "@/api/csr-request";
import {
  CountriesDocument,
  CountriesQueryVariables,
  Country,
  CreateCountryDocument,
  CreateCountryMutationVariables,
  DeleteCountryDocument,
  UpdateCountryDocument,
  UpdateCountryMutationVariables,
} from "@/shared/codegen/types";

async function fetchCountries(request?: CountriesQueryVariables) {
  const result = await authorizedClientRequest({
    document: CountriesDocument,
    variables: request ?? {},
  });

  if (result.countries) return result.countries;
  throw new Error("Couldn't retrieve countries.");
}

async function createCountry(request: CreateCountryMutationVariables): Promise<Country> {
  const response = await authorizedClientRequest({
    document: CreateCountryDocument,
    variables: request,
  });

  if (response.createCountry) return response.createCountry;
  throw new Error("couldn't create country");
}

async function updateCountry(request: UpdateCountryMutationVariables): Promise<Country> {
  const response = await authorizedClientRequest({
    document: UpdateCountryDocument,
    variables: request,
  });

  if (response.updateCountry) return response.updateCountry;
  throw new Error("couldn't update country");
}

async function deleteCountry(id: string): Promise<boolean> {
  const response = await authorizedClientRequest({
    document: DeleteCountryDocument,
    variables: {id},
  });

  if (response.deleteCountry) return response.deleteCountry;
  throw new Error("couldn't delete country");
}

export const CountryModule = {
  fetchCountries,
  createCountry,
  updateCountry,
  deleteCountry,
};
