"use client";

import {message} from "antd";
import React, {useRef} from "react";
import ReactAvatarEditor from "react-avatar-editor";

type Props = {
  image: File;
  onChange: (image: Blob) => void;
};

function ImageEditor({image}: Props): JSX.Element {
  const [_, contextHolder] = message.useMessage();

  const editor = useRef<ReactAvatarEditor>(null);
  const size = 200;
  const scale = 1.2;
  const defaultProps = {
    width: size,
    height: size,
    border: 10,
    color: [255, 255, 255, 0.6], // RGBA
    borderRadius: 0,
  };

  return (
    <div>
      {contextHolder}
      <ReactAvatarEditor {...defaultProps} ref={editor} image={image} scale={scale} />
    </div>
  );
}

export default ImageEditor;
