"use client";

import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";
import type {ColumnsType} from "antd/lib/table";
import React, {useMemo, useState} from "react";

import EmptyTable from "../../empty-table";
import Table from "../../table";
import CRUDDetails from "@/app/_components/crud/details";
import {CRUDFormMetaFunction} from "@/app/_components/crud/types";
import {isNil} from "@/shared/utils/nil";

type TableWidgetProps<R, F> = {
  onChange: (value: R[]) => void;
  value: R[] | null;
  identifier: string;
  disabled?: boolean;
  table: {
    columns: ColumnsType<R>;
  };
  options?: {
    actions?: {
      create?: boolean;
      update?: boolean;
      delete?: boolean;
    };
    header?: {
      extra?: React.ReactNode;
    };
    icons?: {
      default?: (className?: string) => React.ReactNode;
    };
  };
  form: {
    meta: CRUDFormMetaFunction<F>;
  };
};

export const TableWidget = <R extends object, F extends object>({
  value,
  onChange,
  identifier,
  table,
  form,
  options,
  disabled,
}: TableWidgetProps<R, F>) => {
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [selection, setSelection] = useState<R | undefined>(undefined);
  const [recordsOffset, setRecordsOffset] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(10);

  const records = useMemo(() => (!isNil(value) ? value : []), [value]);

  const handleOpenDetails = () => {
    setDetailsIsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsIsOpen(false);
  };

  const handleRowClick = (record: R) => {
    setSelection(record);
    handleOpenDetails();
  };

  const handleCreateClick = () => {
    setSelection(undefined);

    handleOpenDetails();
  };

  const handleCreate = async (values: F) => {
    const result = values as unknown as R;
    onChange([...records, result]);
    setSelection(undefined);
    handleCloseDetails();
    return result;
  };

  const handleUpdate = async (record: R, values: F) => {
    const result = values as unknown as R;
    const stringifiedRecord = JSON.stringify(record);
    const recordIndex = records.findIndex((r) => JSON.stringify(r) === stringifiedRecord);

    const firstRecords = recordIndex > 0 ? records.slice(0, recordIndex) : [];
    const lastRecords = records.slice(recordIndex + 1);
    const newRecords = [...firstRecords, result, ...lastRecords];
    onChange(newRecords);
    setSelection(undefined);
    handleCloseDetails();
    return result;
  };

  const handleDelete = async (record: R) => {
    const stringifiedRecord = JSON.stringify(record);
    onChange(records.filter((r) => JSON.stringify(r) !== stringifiedRecord));
    setSelection(undefined);
    handleCloseDetails();
    return record;
  };

  const shownRecords = useMemo(
    () => records.slice(recordsOffset, recordsOffset + tablePageSize),
    [records, recordsOffset, tablePageSize]
  );

  return (
    <>
      {disabled === false ? (
        <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateClick}>
          New
        </Button>
      ) : undefined}
      <Table<R>
        identifier={identifier}
        totalItems={records.length}
        handleRowClick={handleRowClick}
        setRecordsOffset={setRecordsOffset}
        tablePageSize={tablePageSize}
        setTablePageSize={setTablePageSize}
        columns={table.columns}
        dataSource={shownRecords}
        locale={{emptyText: <EmptyTable />}}
      />

      {detailsIsOpen && (
        <CRUDDetails<R, F>
          record={selection}
          open={detailsIsOpen}
          form={{
            meta: form.meta,
          }}
          options={{
            actions: disabled === false ? options?.actions : {create: false, update: false, delete: false},
            icons: options?.icons,
            modal: {
              type: "modal",
            },
          }}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          onClose={handleCloseDetails}
        />
      )}
    </>
  );
};
