import type {FormInstance} from "antd";

import type {FormMeta} from "@/app/_components/form-builder";

export type CRUDRecord = {
  id?: string | number;
  title?: string;
  notes?: string | null;
  updatedAt?: Date;
};

export enum CRUDMode {
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
}

export type CRUDInstance<F> = {
  mode: CRUDMode;
  form: FormInstance<F>;
};

export type CRUDFormMetaFunction<F> = (instance: CRUDInstance<F>) => FormMeta;
