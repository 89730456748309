"use client";

import {CloseOutlined, DatabaseOutlined, DeleteOutlined, EditOutlined, MenuOutlined} from "@ant-design/icons";
import {Drawer, Dropdown, Modal, notification, Result, Space, Spin, Tabs, Typography} from "antd";
import {useSession} from "next-auth/react";
import {useTranslations} from "next-intl";
import React, {Fragment, Suspense, useCallback, useEffect, useMemo, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import CRUDProperties from "@/app/_components/crud/properties";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import {insertIf} from "@/shared/utils/helpers";
import {Breakpoints} from "@/utils/breakpoints";

import {CRUDRecord, CRUDMode, CRUDFormMetaFunction} from "./types";

type CRUDDetailsProps<R extends CRUDRecord, F> = {
  defaultMode?: CRUDMode;
  record?: R;
  open: boolean;
  form: {
    meta: CRUDFormMetaFunction<F>;
  };
  options?: {
    actions?: {
      create?: boolean;
      update?: boolean;
      delete?: boolean;
    };
    icons?: {
      default?: (className?: string) => React.ReactNode;
    };
    labels?: {
      createTitle?: string;
      createSubtitle?: string;
      updateTitle?: string;
      updateSubtitle?: string;
    };
    modal?: {
      type?: "auto" | "drawer" | "modal";
    };
    tabs?: {
      history?: {
        enabled?: boolean;
      };
      items?: {
        key: string;
        label: string;
        children: React.ReactNode;
      }[];
    };
  };
  onCreate: (values: F) => Promise<R>;
  onUpdate: (record: R, values: F) => Promise<R>;
  onDelete: (record: R) => Promise<R>;
  onClose: () => void;
  onAfterClose?: () => void;
};

const useStyles = createUseStyles({
  titleIcon: {
    fontSize: 30,
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    width: 590,
  },
  dropdown: {
    width: 180,
  },
  tabs: {
    width: "100%",
  },
  "@media (max-width: 575px)": {
    tabs: {
      "&&": {
        "& .ant-tabs-tab": {
          fontSize: 12,
          padding: "8px 0px 8px 8px !important",
        },
        "& .ant-space-item": {
          fontSize: 12,
        },
        "& .ant-typography": {
          fontSize: 12,
        },
        "& .ant-statistic-content": {
          fontSize: 14,
        },
        "& .ant-statistic-title": {
          fontSize: 12,
        },
        "& .ant-descriptions-item-label": {
          fontSize: 10,
          padding: 8,
        },
        "& .ant-descriptions-item-content": {
          fontSize: 10,
          padding: 8,
        },
        "& .ant-tabs-content-holder": {
          overflowY: "auto",
        },
        "& .ant-descriptions-view": {
          overflowY: "auto",
        },
      },
    },
  },
  "@media (min-width: 576px) AND (max-width: 756px)": {
    tabs: {
      "&&": {
        "& .ant-descriptions-item-label": {
          fontSize: 12,
          padding: 8,
        },
        "& .ant-descriptions-item-content": {
          fontSize: 12,
          padding: 8,
        },
      },
    },
  },
});

const CRUDDetails = <R extends CRUDRecord, F>(props: React.PropsWithChildren<CRUDDetailsProps<R, F>>) => {
  const translation = useTranslations("crud");
  const styles = useStyles();
  const theme = useTheme();
  const {data: session} = useSession();
  const {defaultMode, record, open, form, options, onCreate, onUpdate, onDelete, onClose, onAfterClose} = props;
  const [notificationHelper, notificationHolder] = notification.useNotification({maxCount: 1});

  const {width: windowWidth} = useWindowDimensions();
  const isWindowSmall = windowWidth <= Breakpoints.SM;

  const [activeTab, setActiveTab] = useState<string>(`_properties`);

  const [overridingMode, setOverridingMode] = useState<CRUDMode | undefined>(defaultMode);

  useEffect(() => {
    setOverridingMode(defaultMode);
  }, [open, defaultMode]);

  const mode = useMemo<CRUDMode>(
    () => (overridingMode ? overridingMode : record ? CRUDMode.Read : CRUDMode.Create),
    [overridingMode, record]
  );

  const [mask, setMask] = useState(false);

  const deleteModalIsOpen = useMemo(() => open && mode === CRUDMode.Delete, [mode, open]);
  const [deleting, setDeleting] = useState(false);

  const isUpdateEnabled =
    (options?.actions?.update ?? true) &&
    mode !== CRUDMode.Update &&
    (process.env.NODE_ENV === "development" || session?.roles?.isAdministrator);

  const isDeleteEnabled =
    (options?.actions?.delete ?? true) && (process.env.NODE_ENV === "development" || session?.roles?.isAdministrator);

  let title = "";
  let subtitle: string | undefined = undefined;

  switch (mode) {
    case CRUDMode.Create:
      title = options?.labels?.createTitle ?? translation("crudDetailsTitle");
      subtitle = options?.labels?.createSubtitle;
      break;
    case CRUDMode.Update:
      title = options?.labels?.updateTitle ?? record?.title ?? translation("crudDetailsTitle2");
      subtitle = options?.labels?.updateSubtitle;
      break;
    default:
      title = record?.title ?? translation("crudDetailsTitle2");
      break;
  }

  const handleUpdateClick = useCallback(() => {
    setMask(true);
    setActiveTab(`_properties`);
    setOverridingMode(CRUDMode.Update);
  }, []);

  const handleDeleteClick = useCallback(async () => {
    setMask(true);
    setActiveTab(`_properties`);
    setOverridingMode(CRUDMode.Delete);
  }, []);

  const handleDeleteOk = useCallback(async () => {
    setDeleting(true);

    try {
      await onDelete(record!);
    } catch (error) {
      notificationHelper.error({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        message: "An error occurred while deleting the record.",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        description: error.message,
        duration: 0,
      });
    } finally {
      setDeleting(false);

      setMask(false);
      setOverridingMode(undefined);
    }
  }, [notificationHelper, record, onDelete]);

  const handleDeleteCancel = useCallback(async () => {
    setMask(true);
    setOverridingMode(undefined);
  }, []);

  const handleTabChange = useCallback((tab: string) => {
    setActiveTab(tab);
  }, []);

  const handleFormAccept = useCallback(
    async (values: F) => {
      let result: R | undefined = undefined;

      try {
        switch (mode) {
          case CRUDMode.Create: {
            result = await onCreate(values);
            break;
          }
          case CRUDMode.Update: {
            result = await onUpdate(record!, values);
            break;
          }
          default:
            break;
        }
      } catch (error) {
        notificationHelper.error({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          message: `An error occurred while ${mode === CRUDMode.Create ? "creating" : "updating"} the record.`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          description: error.message,
          duration: 0,
        });
      } finally {
        if (result) {
          setOverridingMode(undefined);
          setMask(false);
        }
      }
    },
    [notificationHelper, mode, onCreate, onUpdate, record]
  );

  const handleFormCancel = useCallback(async () => {
    switch (mode) {
      case CRUDMode.Update:
        setOverridingMode(undefined);
        setMask(false);
        break;
      default:
        setMask(false);
        onClose();
        break;
    }
  }, [mode, onClose]);

  const handleCloseClick = useCallback(async () => {
    if (mode !== CRUDMode.Read) {
      handleFormCancel();
      return;
    }

    props.onClose();
  }, [mode, props, handleFormCancel]);

  const handleAfterOpenChange = useCallback(async (open: boolean) => {
    if (!open) {
      onAfterClose?.();
    }
  }, []);

  const tabsItems = useMemo(
    () => [
      {
        label: translation("crudDetailsPropiertiesTabTitle"),
        key: `_properties`,
        children: (
          <Suspense fallback={<Spin size="small" />}>
            <CRUDProperties<R, F>
              key={`_properties-${mode}`}
              mode={mode}
              record={record}
              formMeta={form.meta}
              onFormAccept={handleFormAccept}
              onFormCancel={handleFormCancel}
            />
          </Suspense>
        ),
      },
      ...(options?.tabs?.items ?? []),
      ...insertIf(options?.tabs?.history?.enabled ?? true, {
        label: translation("crudDetailsHistoryTabTitle"),
        key: `_history`,
        children: (
          <Result
            status="404"
            title={translation("crudDetailsUnderConstruction")}
            subTitle={translation("crudDetailsUnderConstructionText")}
          />
        ),
        disabled: mode !== CRUDMode.Read,
      }),
    ],
    [
      translation,
      mode,
      record,
      form.meta,
      handleFormAccept,
      handleFormCancel,
      options?.tabs?.items,
      options?.tabs?.history?.enabled,
    ]
  );

  const titleNode = useMemo(
    () => (
      <Space style={{alignItems: "flex-start"}}>
        {options?.icons?.default ? (
          options!.icons!.default(styles.titleIcon)
        ) : (
          <DatabaseOutlined className={styles.titleIcon} />
        )}
        <Space direction="vertical" size={0}>
          <Typography.Text className={styles.title}>{title}</Typography.Text>
          {subtitle && (
            <Typography.Text className={styles.subtitle} type="secondary" ellipsis>
              {subtitle}
            </Typography.Text>
          )}
        </Space>
      </Space>
    ),
    [options, subtitle, title, styles]
  );

  const actionsNode = useMemo(() => {
    const extraIconStyle = {cursor: "pointer", color: theme.isDark ? "#ffffffd9" : undefined};

    return (
      <Space size={"middle"}>
        {(mode === CRUDMode.Read || mode === CRUDMode.Delete) && (isUpdateEnabled || isDeleteEnabled) && (
          <Dropdown
            overlayClassName={styles.dropdown}
            disabled={mode !== CRUDMode.Read}
            placement="bottomRight"
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "edit",
                  icon: <EditOutlined />,
                  label: translation("crudDetailsEditButton"),
                  disabled: !isUpdateEnabled,
                  onClick: handleUpdateClick,
                },
                {type: "divider"},
                {
                  key: "delete",
                  icon: <DeleteOutlined />,
                  label: translation("crudDetailsDeleteButton"),
                  danger: true,
                  disabled: !isDeleteEnabled,
                  onClick: handleDeleteClick,
                },
              ],
            }}>
            <MenuOutlined style={extraIconStyle} disabled={mode !== CRUDMode.Read} />
          </Dropdown>
        )}
        <CloseOutlined style={extraIconStyle} onClick={handleCloseClick} />
      </Space>
    );
  }, [
    theme.isDark,
    mode,
    isUpdateEnabled,
    isDeleteEnabled,
    styles.dropdown,
    translation,
    handleUpdateClick,
    handleDeleteClick,
    handleCloseClick,
  ]);

  const content = useMemo(
    () => (
      <Fragment key={record?.id}>
        {notificationHolder}
        <Tabs
          activeKey={activeTab}
          animated={false}
          className={styles.tabs}
          items={tabsItems}
          onChange={handleTabChange}
          size={isWindowSmall ? undefined : "middle"}></Tabs>
      </Fragment>
    ),
    [notificationHolder, activeTab, record?.id, tabsItems, styles, isWindowSmall, handleTabChange]
  );

  const deleteModalContent = useMemo(
    () =>
      deleteModalIsOpen && (
        <Modal
          open={deleteModalIsOpen}
          title={translation("crudDetailsModalTitle")}
          okText={translation("crudDetailsDeleteButton")}
          cancelText={translation("crudDetailsCancelButton")}
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
          okButtonProps={{danger: true, loading: deleting}}>
          <span>
            {translation("crudDetailsDeleteText1")}{" "}
            {record && record.title ? `'${record.title}'` : translation("crudDetailsDeleteText2")}?
          </span>
        </Modal>
      ),
    [deleteModalIsOpen, translation, handleDeleteOk, handleDeleteCancel, deleting, record]
  );

  const showModal = (options?.modal?.type ?? "auto") === "auto" ? isWindowSmall : options?.modal?.type === "modal";

  return useMemo(
    () =>
      showModal ? (
        <Modal
          bodyStyle={{}}
          closable={false}
          maskClosable={mode === CRUDMode.Read}
          keyboard={mode === CRUDMode.Read}
          mask={mask}
          open={open}
          title={
            <Space style={{display: "flex", justifyContent: "space-between"}}>
              {titleNode}
              {actionsNode}
            </Space>
          }
          footer={false}
          onCancel={handleCloseClick}
          afterOpenChange={handleAfterOpenChange}>
          {content}
          {deleteModalContent}
        </Modal>
      ) : (
        <Drawer
          closable={false}
          maskClosable={mode === CRUDMode.Read}
          keyboard={mode === CRUDMode.Read}
          mask={mask}
          open={open}
          placement="right"
          size="large"
          title={titleNode}
          extra={actionsNode}
          afterOpenChange={handleAfterOpenChange}>
          {content}
          {deleteModalContent}
        </Drawer>
      ),
    [showModal, mask, open, titleNode, actionsNode, content, deleteModalContent, mode, handleCloseClick]
  );
};

export default CRUDDetails;
