"use client";

import {Table as AntdTable, ConfigProvider} from "antd";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import type {TableProps as AntdTableProps} from "antd/lib/table";
import {useLocale, useTranslations} from "next-intl";
import React from "react";

const TABLE_PAGE_SIZE_KEY = (identifier: string) => `ui.preferences.${identifier}.tablePageSize`;
const TABLE_PAGE_SIZE_DEFAULT = "50";

export type RecordsWithTotalCount<R> = {edges: {node: R}[]; totalCount: number};

type TableProps<R> = AntdTableProps<R> & {
  identifier: string;
  totalItems?: number;
  handleRowClick: (record: R) => void;
  setRecordsOffset: (offset: number) => void;
  tablePageSize: number;
  setTablePageSize: (pageSize: number) => void;
};

const Table = <R extends object>({
  identifier,
  totalItems,
  handleRowClick,
  tablePageSize,
  setTablePageSize,
  setRecordsOffset,
  ...tableProps
}: React.PropsWithChildren<TableProps<R>>) => {
  const translation = useTranslations("crud");
  const defaultTablePageSize = Number(
    window.localStorage.getItem(TABLE_PAGE_SIZE_KEY(identifier)) ?? TABLE_PAGE_SIZE_DEFAULT
  );

  if (tablePageSize != defaultTablePageSize) setTablePageSize(defaultTablePageSize);

  const handlePageSizeChange = (_: number, size: number) => {
    window.localStorage.setItem(TABLE_PAGE_SIZE_KEY(identifier), size.toString());
    setTablePageSize(size);
  };
  const handleRenderRowsCount = (total: number) => `${total} ${translation("tableRows")}`;
  const locale = useLocale();

  return (
    <>
      {
        <ConfigProvider locale={locale === "es" ? esES : enUS}>
          <AntdTable<R>
            onRow={(record) => ({
              style: {cursor: "pointer"},
              onClick: () => handleRowClick(record),
            })}
            pagination={{
              size: "small",
              defaultPageSize: defaultTablePageSize,
              pageSize: tablePageSize,
              showTotal: handleRenderRowsCount,
              showSizeChanger: true,
              total: totalItems,
              onShowSizeChange: handlePageSizeChange,
              onChange(page, pageSize) {
                setRecordsOffset((page - 1) * pageSize);
              },
            }}
            rowKey="id"
            scroll={{x: 1000}}
            showSorterTooltip={false}
            size="small"
            sticky
            {...tableProps}
          />
        </ConfigProvider>
      }
    </>
  );
};

export default Table;
