import {Alert, Button, Form, Space, Typography, Divider, CheckboxProps, Checkbox} from "antd";
import {format} from "date-fns";
import {useTranslations} from "next-intl";
import React, {useMemo} from "react";
import {createUseStyles} from "react-jss";

import {FormBuilder, FormMeta} from "@/app/_components/form-builder";

import {useCRUD} from "./context";
import {CRUDRecord, CRUDMode, CRUDFormMetaFunction} from "./types";

type CRUDPropertiesProps<R extends CRUDRecord, F> = {
  mode: CRUDMode;
  record?: R;
  formMeta: CRUDFormMetaFunction<F>;
  onFormAccept: (values: F) => void;
  onFormCancel: () => void;
};

const useStyles = createUseStyles({
  container: {
    display: "flex",
  },
  form: {
    width: "100%",
  },
  formThreeItemContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  formTwoItemContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  submit: {
    marginBottom: 0,
  },
});

const CRUDProperties = <R extends CRUDRecord, F>(props: React.PropsWithChildren<CRUDPropertiesProps<R, F>>) => {
  const translation = useTranslations("properties");
  const styles = useStyles();
  const {mode, formMeta: formMetaFunction, record, onFormAccept, onFormCancel} = props;

  const {createAnother, setCreateAnother} = useCRUD<R>();

  const [form] = Form.useForm();
  const forceUpdate = FormBuilder.useForceUpdate();
  const [error, setError] = React.useState<Error | unknown | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const isFormInViewMode = useMemo(() => ![CRUDMode.Create, CRUDMode.Update].includes(mode), [mode]);

  const handleFormAccept = async (values: F) => {
    setError(undefined);
    setIsLoading(true);

    try {
      await onFormAccept(values);
    } catch (e) {
      setError(e);
    } finally {
      form.resetFields();
      setIsLoading(false);
    }
  };

  const handleFormCancel = async () => {
    onFormCancel();
  };

  const handleCreateAnotherChange: CheckboxProps["onChange"] = (e) => {
    const newChecked = e.target.checked;
    setCreateAnother(newChecked);
  };

  const UpdatedAtView = <R extends CRUDRecord>({record}: React.PropsWithChildren<Partial<{record: R; value: Date}>>) =>
    record?.updatedAt ? (
      <Typography.Text type="secondary" italic>
        {translation("propertiesUpdatedAt")} {format(new Date(record.updatedAt), "MM/dd/yy HH:mm")}
      </Typography.Text>
    ) : null;

  const formMeta = formMetaFunction({mode: mode, form: form});

  const shouldWrapLabels =
    formMeta.labelWrap ??
    formMeta?.fields?.some((field) => field.label && typeof field.label === "string" && field.label.length >= 15);

  const finalFormMeta: FormMeta = {
    ...formMeta,
    initialValues: mode === CRUDMode.Create ? {...formMeta.initialValues} : {...record},
    viewMode: isFormInViewMode,
    columns: 2,
    fields: [
      ...(formMeta?.fields ? formMeta.fields : []),
      {
        key: "_footer",
        colSpan: 4,
        render: () => (
          <Divider plain orientation="left" style={{marginTop: isFormInViewMode ? 10 : -5, marginBottom: 0}}></Divider>
        ),
      },
      ...(record && isFormInViewMode
        ? [
            {
              key: "updatedAt",
              colSpan: 4,
              viewMode: true,
              viewWidget: UpdatedAtView<R>,
              viewWidgetProps: {record: record},
            },
          ]
        : [
            {
              key: "_separator",
              colSpan: 4,
              render: () => <div style={{marginTop: 0, marginBottom: 15}}></div>,
            },
          ]),
    ],
  };

  return (
    <Space className={styles.container} direction="vertical">
      {error instanceof Error && <Alert type="error" showIcon message="Failed to load" description={error.message} />}

      <Form<F>
        labelWrap={shouldWrapLabels}
        labelAlign="left"
        className={styles.form}
        form={form}
        onValuesChange={forceUpdate}
        onFinish={handleFormAccept}>
        <FormBuilder form={form} meta={finalFormMeta} />

        {!isFormInViewMode && (
          <Form.Item className={styles.submit}>
            <Space
              className={
                mode == CRUDMode.Create && !record ? styles.formThreeItemContainer : styles.formTwoItemContainer
              }>
              {mode == CRUDMode.Create && !record && (
                <Checkbox checked={createAnother} onChange={handleCreateAnotherChange}>
                  {translation("propertiesCreateAnotherButton")}
                </Checkbox>
              )}
              <Space>
                <Button disabled={isLoading} onClick={handleFormCancel}>
                  {translation("propertiesCancelButton")}
                </Button>
                <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
                  {translation("propertiesAcceptButton")}
                </Button>
              </Space>
            </Space>
          </Form.Item>
        )}
      </Form>
    </Space>
  );
};

export default CRUDProperties;
