import {useQuery} from "@tanstack/react-query";
import {Select} from "antd";
import React, {useState, useEffect} from "react";

import {CountryModule} from "@/modules/country";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CountryWidget = ({value, onChange}: any) => {
  const [options, setOptions] = useState<{value: string; label: string}[]>([]);

  const {data} = useQuery({queryKey: ["countries"], queryFn: () => CountryModule.fetchCountries()});

  useEffect(() => {
    if (data) {
      const options = data.edges.map(({node: country}) => {
        //label is either the nickname or the name
        let labelText = country.name;

        //shouldn't happen since firstName's required constraint, but at least show id if it fails
        if (!labelText) labelText = `id: ${country.code}`;

        return {
          value: country.code,
          label: labelText,
        };
      });
      setOptions(options);
    }
  }, [data]);

  const handleChange = (value: string) => onChange?.(value);

  return !value && !onChange ? (
    ""
  ) : (
    <Select
      value={value}
      disabled={!onChange}
      options={options}
      placeholder="Select Country"
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
      onChange={handleChange}
    />
  );
};
