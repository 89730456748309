"use client";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileExclamationOutlined,
  FileOutlined,
  FileSyncOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCallback} from "react";
import {createUseStyles} from "react-jss";

import {FileModule} from "@/modules/file";
import {File as FileModel, FileStatus} from "@/shared/codegen/types";
import {insertIf} from "@/shared/utils/helpers";

type PendingFile = {id: string; name: string; file: File};
type PropsForPendingFile = {
  file: PendingFile;
  handleCancel: (file: PendingFile) => void;
};

type PropsForUploadingFile = PropsForPendingFile & {
  status: "Uploading";
};

type PropsForNotUploadedFile = PropsForPendingFile & {
  status: "NotUploaded";
  handleRetry: (file: PendingFile) => void;
};

type PropsForUploadedFile = {
  file: FileModel;
  status: "Uploaded";
  disableDelete?: true;
  invalidateQueryKeyOnDelete?: QueryKey;
  handleDelete?: (id: string) => void;
};

type Props = PropsForNotUploadedFile | PropsForUploadingFile | PropsForUploadedFile;

const useStyles = createUseStyles(({token}) => ({
  icon: {
    fontSize: "1.5rem",
    color: token.colorIcon,
  },
  file: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    margin: "4px",
    padding: "8px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: token.colorBorder,
  },
  trashIcon: {
    fontSize: "1.2rem",
    color: "red",
  },
  actions: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    transition: "opacity 150ms ease-in-out",
    rigth: "8px",
  },
  date: {
    fontSize: "8",
    color: token.colorTextSecondary,
  },
  details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "right",
    gap: "10px",
    width: "30%",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "70%",
  },
}));

export const FileCard = ({file, status, ...props}: Props) => {
  const queryKey = "invalidateQueryKeyOnDelete" in props ? props.invalidateQueryKeyOnDelete : undefined;
  const styles = useStyles();

  const queryClient = useQueryClient();

  const handleFileDelete = useCallback(
    async (file: FileModel) => {
      if ("handleDelete" in props) {
        props.handleDelete?.(file.id);
        return;
      }
      FileModule.deleteFile({id: file.id})
        .then(() => {
          queryClient.invalidateQueries({queryKey});
        })
        .catch(() => {
          console.error("Error when deleting file");
        });
    },
    [queryClient, queryKey, props]
  );

  const handleFileDownload = useCallback(async (file: FileModel) => {
    let url;
    if (file.storageDownloadUrl) {
      url = file.storageDownloadUrl;
    } else {
      const result = await FileModule.downloadUrl({id: file.id});
      url = result.url;
    }
    if (url) {
      window.open(url, "_blank");
    }
  }, []);

  let actions = null;
  let icon = <FileOutlined className={styles.icon} />;
  if (status === "Uploaded") {
    if (file.status === FileStatus.Ready) {
      actions = (
        <div className={styles.actions}>
          <DownloadOutlined
            className={styles.icon}
            onClick={() => {
              handleFileDownload(file);
            }}
          />
          {insertIf(
            !("disableDelete" in props) || props.disableDelete !== true,
            <DeleteOutlined
              className={styles.trashIcon}
              onClick={() => {
                handleFileDelete(file);
              }}
            />
          )}
        </div>
      );
    } else {
      icon = <FileExclamationOutlined className={styles.icon} />;
    }
  }
  if (status === "Uploading") {
    icon = <FileSyncOutlined className={styles.icon} />;
  }
  if (status === "NotUploaded" && "handleRetry" in props) {
    icon = <FileExclamationOutlined className={styles.icon} />;
    actions = (
      <div className={styles.actions}>
        <RedoOutlined
          className={styles.icon}
          onClick={() => {
            props.handleRetry(file);
          }}
        />
        <DeleteOutlined
          className={styles.trashIcon}
          onClick={() => {
            props.handleCancel(file);
          }}
        />
      </div>
    );
  }
  return (
    <div className={styles.file} key={file.id}>
      {icon}
      <div className={styles.content}>
        <p>{file.name}</p>
      </div>
      <div className={styles.details}>
        {status === "Uploaded" ? <p className={styles.date}>{new Date(file.createdAt).toLocaleString()}</p> : null}
        {actions}
      </div>
    </div>
  );
};
