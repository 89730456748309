"use client";

import {Image as ImageAntD} from "antd";
import React, {useState} from "react";

import FileUploadButton from "../../file-upload-button";
import ImageEditor from "../../image-editor";
import {isNil} from "@/shared/utils/nil";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TodoImageWidget = ({value, onChange}: any) => {
  const [originalImage, setOriginalImage] = useState<File | undefined>();
  const handleChange = (files: File[]) => {
    onChange(files[0]);
    setOriginalImage(files[0]);
  };

  if (isNil(value) && isNil(onChange)) return "";

  if (originalImage) {
    return <ImageEditor image={originalImage} onChange={onChange} />;
  }

  if (isNil(value)) {
    if (!isNil(onChange)) {
      return <FileUploadButton handleFiles={handleChange} singleFileUpload={true}></FileUploadButton>;
    }
    return <></>;
  }
  if (isNil(onChange)) {
    return <ImageAntD width={200} src={value} />;
  }
  return (
    <FileUploadButton
      uploadButtonContent={<ImageAntD width={200} src={value} preview={false} />}
      handleFiles={handleChange}
      singleFileUpload={true}
    />
  );
};
